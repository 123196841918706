import React, { Suspense } from 'react'
import './Routes.css'

// Dependencias Internas
import Loading from './components/loading/Loading'
import Institucional from './components/institucional/Home'

//Dependencias Externas
import { BrowserRouter, Switch, Route } from 'react-router-dom'

const Post = React.lazy(() => import('./components/institucional/nosso-blog/Posts/Post'));
const SobreNos = React.lazy(() => import('./components/institucional/sobre-nos/Sobrenos'));
const FormProdutoPersonalizado = React.lazy(() => import('./components/institucional/form-produto-personalizado/FormProdutoPersonalizado'));
const FAQ = React.lazy(() => import('./components/institucional/faq/Faq'));
const Termos = React.lazy(() => import('./components/institucional/termos/Termos'));
const Signature = React.lazy(() => import('./components/others/signature/Signature'));

export default props =>
    <Suspense fallback={<Loading />}>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact={true} component={Institucional} />
                <Route path="/home" exact={true} component={Institucional} />
                <Route path="/sobre-nos" exact={true} component={SobreNos} />
                <Route path="/signature" exact={true} component={Signature} />
                <Route path="/faq" exact={true} component={FAQ} />
                <Route path="/termos" exact={true} component={Termos} />
                <Route path="/blog/post/:id" exact={true} component={Post} />
                <Route path="/produto-personalizado" exact={true} component={FormProdutoPersonalizado} />
            </Switch>
        </BrowserRouter>
    </Suspense>
