import React from "react";
import "./Footer.css";

//Dependencias Internas
import Logo from "../../../assets/images/logo_footer.png";

//Dependencias Externas
import "@fortawesome/fontawesome-free/css/all.min.css";

export default (props) => (
    <footer className='footer' id='footer'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-auto gx-5 footer-social'>
                    <img src={Logo} alt='Logotipo Léo Acrilicos' className="img-fluid" />
                    <div className='social-icons'>
                        <a
                            href='https://www.facebook.com/leoacrilicosoficial/'
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            <i className='fab fa-facebook-f'></i>
                        </a>
                        <a href='https://www.instagram.com/LeoAcrilicos/' target='_blank' rel="noopener noreferrer">
                            <i className='fab fa-instagram'></i>
                        </a>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                    <h3>Menu</h3>
                    <ul>
                        <li>
                            <a href='/home'>Home</a>
                        </li>
                        <li>
                            <a href='/sobre-nos'>Sobre nós</a>
                        </li>
                        <li>
                            <a href='/blog'>Blog</a>
                        </li>
                        <li>
                            <a href='https://shop.leoacrilicos.com.br'>Loja</a>
                        </li>
                    </ul>
                </div>
                <div className='col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2'>
                    <h3>Help</h3>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-md-12 help-col'>
                            <ul>
                                <li>
                                    <a href='/termos'>Termos & Condições</a>
                                </li>
                                <li>
                                    <a href='/faq'>FAQ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-md-7 col-lg-5 col-xl-5 mx-auto mb-md-0 mb-4 sugestoes'>
                    <h3>Alguma Sugestão?</h3>
                    <ul>
                        <li>
                            <i className='fas fa-map-marker-alt'></i> R. Perez Casas, 65
                            Parque Ipê – São Paulo
                        </li>
                        <li>
                            <i className='fas fa-phone-alt'></i> (11) 99550-7516
                        </li>
                        <li>
                            <i className='fas fa-envelope'></i> vendas@leoacrilicos.com.br
                        </li>
                    </ul>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 copyright'>
                    Copyright &copy; 2022 Todos os direitos reservados
                </div>
            </div>
        </div>
    </footer>
);
